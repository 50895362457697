<template>
  <div class="delivery-record-box">
    <van-nav-bar title="投递记录" class="header" />
    <van-dropdown-menu active-color="#206cfe" class="delivery-dropdown">
      <van-dropdown-item title="筛选" ref="item">
        <van-search v-model="searchVal" placeholder="请输入职位名称" @search="search" />
        <van-checkbox-group v-model="positionIds">
          <van-cell-group>
            <van-cell
              v-for="(item, i) in allPositions"
              clickable
              :key="i"
              :title="item.text"
              @click="toggle(i, item)"
            >
              <template #right-icon>
                <van-checkbox :name="item.value" ref="checkboxes"></van-checkbox>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div style="padding: 5px 16px">
          <van-button type="primary" color="#206cfe" block round @click="onPositionConfirm">确认</van-button>
        </div>
      </van-dropdown-item>
      <van-dropdown-item v-model="state" :options="deliveryStateList" />
    </van-dropdown-menu>
    <div class="record-list-box">
      <van-list
        class="record-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="(item, i) in list" :key="i">
          <div class="list-top">
            <span>{{ item.positionName }}</span>
            <!-- <i>附件简历</i> -->
            <!-- 在线简历 -->
            <i v-if="item.resumeType == 'ONLINE'" @click="openPreview(item)">简历预览</i>
            <!-- 附件简历  -->
            <i
              v-if="
                item.resumeType == 'ATTACHMENT' &&
                item.deliveryStateCode == 'COMPANYFILTERFAIL'
              "
              class="previewDisabled"
            >简历预览</i>
            <i
              v-else-if="
                item.resumeType == 'ATTACHMENT' &&
                item.deliveryStateCode == 'INTERVIEWFAIL'
              "
              class="previewDisabled"
            >简历预览</i>
            <i
              v-else-if="
                item.resumeType == 'ATTACHMENT' &&
                  item.deliveryStateCode != 'INTERVIEWFAIL' ||
                item.deliveryStateCode == 'COMPANYFILTERFAIL'
              "
              @click="openDown(item)"
            >简历预览</i>
          </div>
          <div class="list-btm">
            <div class="list-view">
              <span>
                <img :src="require(`../../assets/deliveryRecord/user.png`)" alt />
                {{ item.userName }}
              </span>
              <em></em>
              <span>
                <img :src="require(`../../assets/deliveryRecord/tel.png`)" alt />
                {{ item.mobile }}
              </span>
            </div>
            <div class="list-handle">
              <van-button
                color="#206CFE"
                round
                size="small"
                type="primary"
                :disabled="
                  item.deliveryStateCode !== 'PLATFORMFILTERPASS' &&
                  item.deliveryStateCode !== 'COMPANYFILTERFPASS' &&
                  item.deliveryStateCode !== 'INTERVIEWPASS'
                "
                @click="handleResume(item)"
              >
                {{ item.deliveryStateCode | statusFilter(item) }}
                <template
                  v-if="
                    item.deliveryStateCode === 'PLATFORMFILTERPASS' ||
                    item.deliveryStateCode === 'COMPANYFILTERFPASS' ||
                    item.deliveryStateCode === 'INTERVIEWPASS'
                  "
                >
                  <van-icon v-if="showPicker" name="arrow-up" />
                  <van-icon v-else name="arrow-down" />
                </template>
              </van-button>
            </div>
          </div>
          <div class="list-reason" v-if="item.deliveryStateCode == 'COMPANYFILTERFAIL' || item.deliveryStateCode == 'INTERVIEWFAIL'">
            未通过原因：{{ item.reason || '-' }}
          </div>
        </van-cell>
      </van-list>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm">
        <template #option="item">
          <p style="text-align: center">{{ item.name }}</p> 
        </template>
      </van-picker>
    </van-popup>
    <van-popup v-model="reasonShow" closeable round :style="{ width:'80%' }">
      <van-form class="form" @submit="reasonSubmit">
        <div class="reason-title">未通过原因</div>
        <div class="filed-box">
          <van-field
            v-model="reason"
            rows="3"
            autosize
            label
            type="textarea"
            placeholder="请输入未通过原因"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <van-button size="small" round block type="info" native-type="submit">提交</van-button>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'deliveryRecord',
  data() {
    return {
      searchVal: '', // 搜索内容
      handleName: '', // 处理按钮文案
      state: 'ALL', // 状态
      allPositions: [], // 所有职位
      positionIds: [], // 选中职位
      deliveryStateList: [], //状态字典
      columns: [], // 单条数据的处理下拉
      loading: false,
      finished: false,
      showPicker: false,
      resumePicker: false, // 附件简历弹窗
      query: {
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      list: [], // 查询列表结果
      handling: null, // 正在处理的那条数据
      checkState: null, //选中的处理
      reasonShow: false,// 未通过原因弹窗是否显示
      reason: '',// 未通过原因
    };
  },
  watch: {
    state: {
      handler(N, O) {
        if (N !== O) {
          this.getDeliveryList();
        }
      },
    },
  },
  mounted() {
    this.getAllPositions();
    this.getDeliveryStateList();
    let { query } = this.$route;
    if (query.state && query.state === 'PLATFORMFILTERPASS') {
      this.state = 'PLATFORMFILTERPASS';
    }
  },
  filters: {
    statusFilter(data, item) {
      if (data == 'PLATFORMFILTERPASS') {
        return '企业评估中';
      } else if (data == 'COMPANYFILTERFPASS') {
        return '面试中';
      } else if (data == 'INTERVIEWPASS') {
        return '入职结果';
      } else {
        return item.stateDesc;
      }
    },
  },
  methods: {
    options(item) {
      var arr = [];
      if (item.deliveryStateCode == 'WAITING') {
        arr = [
          { code: 'PLATFORMFILTERPASS', name: '企业评估中' },
          { code: 'COMPANYFILTERFPASS', name: '企业筛选通过·可约面试' },
          { code: 'COMPANYFILTERFAIL', name: '企业筛选未通过' },
        ];
      }
      if (item.deliveryStateCode == 'PLATFORMFILTERPASS') {
        arr = [
          { code: 'COMPANYFILTERFPASS', name: '企业筛选通过.可约面试' },
          { code: 'COMPANYFILTERFAIL', name: '企业筛选未通过' },
        ];
      }
      if (item.deliveryStateCode == 'COMPANYFILTERFPASS') {
        arr = [
          { code: 'INTERVIEWPASS', name: '面试通过' },
          { code: 'INTERVIEWFAIL', name: '面试未通过' },
        ];
      }
      if (item.deliveryStateCode == 'INTERVIEWPASS') {
        arr = [
          { code: 'EMPLOYED', name: '已入职' },
          { code: 'GIVE_UP_ENTRY', name: '放弃入职' },
        ];
      }
      return arr;
    },
    handleResume(item) {
      this.columns = this.options(item);
      this.handling = item;
      this.showPicker = true;
    },
    search() {
      this.positionIds = [];
      this.getAllPositions();
    },
    onPositionConfirm() {
      this.getDeliveryList();
      this.$refs.item.toggle(false);
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    //预览求职者详情
    async openPreview(item) {
      const {
        id,
        resumeUserId,
        deliveryId,
        resumeLibraryTypeI,
        companyName,
        positionName,
        userName,
        mobile,
        deliveryStateCode,
        stateDesc,
      } = item;
      const resumeLibraryType = resumeLibraryTypeI;
      this.$router.push({
        path: '/deliveryRecord/previewOnline',
        query: {
          id,
          resumeUserId,
          deliveryId,
          resumeLibraryType,
          companyName,
          positionName,
          userName,
          mobile,
          deliveryStateCode,
          stateDesc,
        },
      });
    },
    // 下载求职者信息
    async openDown(item) {
      // id暂时写死
      // window.open(item.fileUrl, '_blank');
      const res = await this.$http.get(
        '/selfhelpOfficialAccounts/job-seekers/previewAttachedResume',
        {
          resumeUserId: item.resumeUserId,
          resumeLibraryType: item.resumeLibraryTypeI,
          url: item.fileUrl,
        }
      );
      if (res.code === 200) {
        this.previewUrl = decodeURIComponent(this.$route.query.previewUrl);
        const { id, deliveryStateCode, stateDesc } = item;
        this.$router.push({
          path: '/deliveryRecord/preview',
          query: {
            previewUrl: encodeURIComponent(res.data.previewUrl),
            url: encodeURIComponent(res.data.url),
            id,
            deliveryStateCode,
            stateDesc,
          },
        });
      }
    },
    // 简历处理
    onConfirm(value) {
      console.log(value)
      this.checkState = value
      if (value.code === "INTERVIEWFAIL" || value.code === "COMPANYFILTERFAIL") {
        this.showPicker = false;
        this.reasonShow = true
      } else {
        this.$dialog
        .confirm({
          message: `确认要${value.name}么？`,
        })
        .then(() => {
          this.showPicker = false;
          this.updateState()
        });
      }
    },
    reasonSubmit () {
      this.updateState()
    },
    updateState() {
      this.$http
        .put(
          `/selfhelpOfficialAccounts/deliveryRecord/${this.handling.id}/${this.checkState.code}`,{reason: this.reason}
        )
        .then(async (res) => {
          if (res.code === 200) {
            this.reasonShow = false
            this.reason = null
            this.$toast('处理成功，正在刷新数据');
            this.getDeliveryList();
            this.$forceUpdate();
          }
        });
    },
    onLoad() {
      this.getDeliveryList(false);
    },
    // 请求所有职位
    getAllPositions() {
      this.$http
        .get('/selfhelpOfficialAccounts/positionSelfhelp/allPositions', {
          name: this.searchVal,
        })
        .then((res) => {
          if (res.code === 200) {
            this.allPositions = res.data.map((el) => {
              el.text = el.name;
              el.value = el.id;
              return el;
            });
            // this.allPositions.unshift({ text: '全部职位', value: '' });
          }
        });
    },
    // 状态字典
    getDeliveryStateList() {
      this.$http
        .get('/selfhelpOfficialAccounts/deliveryRecord/state/search')
        .then((res) => {
          if (res.code === 200) {
            this.deliveryStateList = res.data.map((el) => {
              el.text = el.name;
              el.value = el.code;
              return el;
            });
          }
        });
    },
    // 请求列表
    getDeliveryList(isFirst = true) {
      if (isFirst) {
        this.loading = false;
        this.finished = false;
        this.total = 0;
        this.query = {
          pageIndex: 1,
          pageSize: 10,
        };
        this.list = [];
      }
      this.loading = true;
      this.$http
        .get('/selfhelpOfficialAccounts/deliveryRecord', {
          ...this.query,
          state: this.state,
          positionIds: Object.assign([], this.positionIds).join(','),
        })
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.loaded = true;
            this.list = this.list.concat(res.data);
            if (this.list.length >= res.pagination.totalRow) {
              this.finished = true;
            } else {
              this.query.pageIndex++;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-record-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  .header {
    height: 44px;
  }
  .record-list-box {
    flex: 1;
    overflow-y: auto;
    padding: 0 15px;
    .record-list {
      i {
        font-style: normal;
      }
      .van-cell {
        background: #ffffff;
        border-radius: 4px;
        margin-top: 20px;
      }
      .list-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        i {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #206cfe;
        }
        .previewDisabled {
          color: #ccc;
        }
      }
      .list-btm {
        display: flex;
        justify-content: space-between;
        .list-view {
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            display: flex;
            align-items: center;
            img {
              width: 9px;
              height: 12px;
              margin-right: 5px;
            }
            color: #999999;
            i {
              color: #333333;
            }
          }
          em {
            margin: 0 10px;
            height: 6px;
            width: 1px;
            display: inline-block;
            background: #cccccc;
          }
        }
        .list-handle {
          .van-button--small {
            height: 24px;
            line-height: 24px;
          }
          .van-button__text {
            margin: 0 3px;
          }
        }
      }
      .list-reason {
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f44336;
      }
    }
  }
  .delivery-dropdown {
    ::v-deep .van-dropdown-item__content {
      display: flex;
      flex-direction: column;
    }
    .van-checkbox-group {
      flex: 1;
      overflow-y: auto;
    }
  }
  .form {
    padding: 10px;
    .reason-title {
      height: 25px;
      text-align: center;
      line-height: 35px;
    }
    .van-cell {
      padding: 8px 0;
    }
  }
}
</style>
