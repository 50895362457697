var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delivery-record-box"},[_c('van-nav-bar',{staticClass:"header",attrs:{"title":"投递记录"}}),_c('van-dropdown-menu',{staticClass:"delivery-dropdown",attrs:{"active-color":"#206cfe"}},[_c('van-dropdown-item',{ref:"item",attrs:{"title":"筛选"}},[_c('van-search',{attrs:{"placeholder":"请输入职位名称"},on:{"search":_vm.search},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('van-checkbox-group',{model:{value:(_vm.positionIds),callback:function ($$v) {_vm.positionIds=$$v},expression:"positionIds"}},[_c('van-cell-group',_vm._l((_vm.allPositions),function(item,i){return _c('van-cell',{key:i,attrs:{"clickable":"","title":item.text},on:{"click":function($event){return _vm.toggle(i, item)}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-checkbox',{ref:"checkboxes",refInFor:true,attrs:{"name":item.value}})]},proxy:true}],null,true)})}),1)],1),_c('div',{staticStyle:{"padding":"5px 16px"}},[_c('van-button',{attrs:{"type":"primary","color":"#206cfe","block":"","round":""},on:{"click":_vm.onPositionConfirm}},[_vm._v("确认")])],1)],1),_c('van-dropdown-item',{attrs:{"options":_vm.deliveryStateList},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('div',{staticClass:"record-list-box"},[_c('van-list',{staticClass:"record-list",attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,i){return _c('van-cell',{key:i},[_c('div',{staticClass:"list-top"},[_c('span',[_vm._v(_vm._s(item.positionName))]),(item.resumeType == 'ONLINE')?_c('i',{on:{"click":function($event){return _vm.openPreview(item)}}},[_vm._v("简历预览")]):_vm._e(),(
              item.resumeType == 'ATTACHMENT' &&
              item.deliveryStateCode == 'COMPANYFILTERFAIL'
            )?_c('i',{staticClass:"previewDisabled"},[_vm._v("简历预览")]):(
              item.resumeType == 'ATTACHMENT' &&
              item.deliveryStateCode == 'INTERVIEWFAIL'
            )?_c('i',{staticClass:"previewDisabled"},[_vm._v("简历预览")]):(
              item.resumeType == 'ATTACHMENT' &&
                item.deliveryStateCode != 'INTERVIEWFAIL' ||
              item.deliveryStateCode == 'COMPANYFILTERFAIL'
            )?_c('i',{on:{"click":function($event){return _vm.openDown(item)}}},[_vm._v("简历预览")]):_vm._e()]),_c('div',{staticClass:"list-btm"},[_c('div',{staticClass:"list-view"},[_c('span',[_c('img',{attrs:{"src":require(`../../assets/deliveryRecord/user.png`),"alt":""}}),_vm._v(" "+_vm._s(item.userName)+" ")]),_c('em'),_c('span',[_c('img',{attrs:{"src":require(`../../assets/deliveryRecord/tel.png`),"alt":""}}),_vm._v(" "+_vm._s(item.mobile)+" ")])]),_c('div',{staticClass:"list-handle"},[_c('van-button',{attrs:{"color":"#206CFE","round":"","size":"small","type":"primary","disabled":item.deliveryStateCode !== 'PLATFORMFILTERPASS' &&
                item.deliveryStateCode !== 'COMPANYFILTERFPASS' &&
                item.deliveryStateCode !== 'INTERVIEWPASS'},on:{"click":function($event){return _vm.handleResume(item)}}},[_vm._v(" "+_vm._s(_vm._f("statusFilter")(item.deliveryStateCode,item))+" "),(
                  item.deliveryStateCode === 'PLATFORMFILTERPASS' ||
                  item.deliveryStateCode === 'COMPANYFILTERFPASS' ||
                  item.deliveryStateCode === 'INTERVIEWPASS'
                )?[(_vm.showPicker)?_c('van-icon',{attrs:{"name":"arrow-up"}}):_c('van-icon',{attrs:{"name":"arrow-down"}})]:_vm._e()],2)],1)]),(item.deliveryStateCode == 'COMPANYFILTERFAIL' || item.deliveryStateCode == 'INTERVIEWFAIL')?_c('div',{staticClass:"list-reason"},[_vm._v(" 未通过原因："+_vm._s(item.reason || '-')+" ")]):_vm._e()])}),1)],1),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.columns},on:{"cancel":function($event){_vm.showPicker = false},"confirm":_vm.onConfirm},scopedSlots:_vm._u([{key:"option",fn:function(item){return [_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.name))])]}}])})],1),_c('van-popup',{style:({ width:'80%' }),attrs:{"closeable":"","round":""},model:{value:(_vm.reasonShow),callback:function ($$v) {_vm.reasonShow=$$v},expression:"reasonShow"}},[_c('van-form',{staticClass:"form",on:{"submit":_vm.reasonSubmit}},[_c('div',{staticClass:"reason-title"},[_vm._v("未通过原因")]),_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"rows":"3","autosize":"","label":"","type":"textarea","placeholder":"请输入未通过原因","rules":[{ required: true, message: '' }]},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('van-button',{attrs:{"size":"small","round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }